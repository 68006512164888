import React, {useState, useEffect} from 'react';

import TopMenu from '../InnerComponents/TopMenu';
import TableStato from './TableStato';
import StatoPopup from './StatoPopup';
import RegistroStato from './RegistroStato';
import Caricamento from "../Caricamento.js";

import styled from "styled-components";
import { Slide } from 'react-reveal';

//icon
import { FaPlus } from "react-icons/fa";
import { MdKeyboardArrowUp } from "react-icons/md";

import axios from "axios";

function StatoPatrimoniale({username, password}) {
  const [aggiungi, setAggiungi] = useState(false);
  function handleSeeAggiungi(){
    setAggiungi(!aggiungi);
  }

  const [popup, setPopup] = useState(false);
  const [tipoPopup, setTipoPopup] = useState(null);
  function handlePopup(tipo){
    setPopup(!popup);
    setTipoPopup(tipo);
  }

  //dati
  const [data, setData] = useState(null);
  const [anniSelezionati, setAnniSelezionati] = useState([]);
  const [macro, setMacro] = useState(null);
  const [categorieLista, setCategorieLista] = useState(null);
  const [registroDati, setRegistroDati] = useState(null);

  const [caricamento, setCaricamento] = useState(false);
  
  const fetchData = async () => {
    setCaricamento(true);
    try {
      const response = await axios.get('https://www.app.nauticsm.it/stato-patrimoniale');
      setData(response.data);
      setAnniSelezionati(response.data.anni);
      setMacro(response.data.macro);
      setCategorieLista(response.data.categorie);
      setRegistroDati(response.data.registro);
      setCaricamento(false);
    } catch (error) {
      console.error('Errore durante la richiesta dati:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  async function ricarica(){
    console.log("ricarica1...");
    await fetchData();
  }
  
  //bottom menu
  const [bottom, setBottom] = useState(false);

  function handleBottom(){
    setBottom(!bottom);
  }

  return (
    <>
      <div className={bottom ? 'opacity' : ''}>
        <TopMenu name="Stato Patrimoniale" username={username}/>

        <Center>
          {data && (<TableStato dati={data} anni={anniSelezionati}/>)}
        </Center>

        <ContainerAggiungi>
          <div>
            <Slide right effect="slideInRight" duration={700} when={aggiungi} >
              <button onClick={() => handlePopup("categoria")}>Categoria</button>
            </Slide>
            <Slide right effect="slideInRight" delay={200} duration={700} when={aggiungi} >
              <button onClick={() => handlePopup("importo")}>Importo</button>
            </Slide>
          </div>
          <div className='aggiungi' onClick={handleSeeAggiungi}>
            <PlusIcon aggiungi={aggiungi}/>
          </div>
        </ContainerAggiungi>

        {(popup && data) && (<StatoPopup close={handlePopup} tipo={tipoPopup} macro={macro} ricarica={ricarica} categorie={categorieLista} anni={anniSelezionati}/>)}
      </div>
      
      <BottomMenuContainer bottom={bottom}>
            <MenuButton bottom={bottom} onClick={handleBottom}/>
            <RegistroStato ricaricaDati={ricarica} registro={registroDati}/> 
      </BottomMenuContainer>

      {caricamento && (
        <Caricamento />
      )}

    </>
  )
}

export default StatoPatrimoniale;

const BottomMenuContainer = styled.div`
  height: ${({ bottom }) => (bottom ? '80%' : '0%')};
  width: 95%;
  overflow: hidden;
  transition: height 0.3s ease;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
  left: 50%;
  overflow: visible;
  background-color: #36454F;
  

  transform: translateX(-50%);
  border-radius: 10px 10px 0px 0px;
  z-index: 9000;

  .menu-item ul li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
  }

`

const MenuButton = styled(MdKeyboardArrowUp )`
  font-size: 50px;
  cursor:pointer;
  background-color: #36454F;
  backdrop-filter: blur(4px); 
  border-radius: 10px;
  z-index: 2000;
  position: fixed;
  right: 50%;
  top: 0;
  color: white;
  transform: ${({bottom}) => (bottom ? 'translate(-50%, -50%) rotate(180deg)' : 'translate(-50%, -70%) rotate(0deg)')}
  
  
`

const Center = styled.div`
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;  
`

const ContainerAggiungi = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;

  div{
    display: flex;
    flex-direction: column;
    overflow:hidden;
  }
  button{
    font-size: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 10px;
  }
  button:nth-child(1){
    background-color: red;
    color: white;
  }
  button:nth-child(2){
    margin: 20px 0;
    background-color: green;
    color: white;
  }

  
`

const PlusIcon = styled(FaPlus)`
  font-size: 54px;
  color: white;
  padding: 10px;
  transition: all .2s linear;
  ${({ aggiungi }) => (aggiungi && 'transform: rotateZ(45deg);')};
`