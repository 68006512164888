import React, {useEffect, useState} from 'react';

import axios from "axios";

import styled from "styled-components";

//icon
import { IoMdClose } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import { VscGraph } from "react-icons/vsc";
import { MdFilterAltOff } from "react-icons/md";

//Components
import TopMenu from '../InnerComponents/TopMenu';
import VenditePopup from './VenditePopup';
import GraphPopup from './GraphPopup';
import Fatturato from './Fatturato';
import Caricamento from "../Caricamento.js";

function Vendite({username, password}) {

    //richiesta dati
    const [dati, setDati] = useState(null);
    const [anni, setAnni] = useState(null);
    const [datiFatturato, setDatiFatturato] = useState(null);
    const [businessUnit, setBusinessUnit] = useState(null);
    const [datiGrafico, setDatiGrafico] = useState(null);
    const [datiAggiuntivi, setDatiAggiuntivi] = useState(null);
    //selezione divisioni aziendali
    const [checkedDivisioni, setCheckedDivisioni] = useState(["Tutto"]);
    const [start, setStart] = useState(true);
    const [anniSelezionati, setAnniSelezionati] = useState([]);
    const [chiamataAnni, setChiamataAnni] = useState(false);

    const fetchData = async () => {
        try {
            const data = {
                divisioni: checkedDivisioni,
                anni: anniSelezionati
            };
    
            const response = await axios.post('https://www.app.nauticsm.it/vendite', data);
            
            setDati(response.data.vendite);
            setAnni(response.data.anni);
            setDatiFatturato(response.data.fatturato_dati);
            setBusinessUnit(response.data.business);
            setDatiGrafico(response.data.grafico);
            setDatiAggiuntivi(response.data.datiAggiuntivi);
            if(!chiamataAnni){
             
              setAnniSelezionati(response.data.anni);
            } else {
              setChiamataAnni(false);
            }


        } catch (error) {
            console.error('Errore durante la richiesta:', error);
        }
    };
    

    

    //see legend divisioni
    const [seeDivisioni, setSeeDivisioni] = useState(false);
    function handleLegend(){
        setSeeDivisioni(!seeDivisioni);
    }


    function handleChangeDivisioni(event){
        const value = event.target.value;
        
        if(value !== 'Tutto' && checkedDivisioni.includes("Tutto")){
        const initialValue = "Tutto";
        const filteredDivisioni = checkedDivisioni.filter(item => item !== initialValue);
        setCheckedDivisioni([...filteredDivisioni, value]);

        } else if(value === "Tutto"){
        setCheckedDivisioni([value]);
        } else {
        if (checkedDivisioni.includes(value)) {
            setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
        } else {
            setCheckedDivisioni([...checkedDivisioni, value]);
        }
        }
    };

    function handleRemoveDivisioni(item){
        const value = item;
        const initialValue = "Tutto";
        console.log(checkedDivisioni.length);
        if(checkedDivisioni.length === 1){
        setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
        setCheckedDivisioni([initialValue]);
        }else {
        setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
        }

    };

    useEffect(() => {
        
        if(!start){
          
          fetchData();
          
        }
        
    }, [checkedDivisioni, start])

    useEffect( () => {
      
      setStart(false);
      
    }, [])


    //year selection
    function handleYearSelection(e){
      const value = parseInt(e.target.value, 10);

      let nuovoArray;
      if (anniSelezionati.includes(value)) {
        console.log(anniSelezionati);

        nuovoArray = anniSelezionati.filter(item => item !== value);
      } else {
        nuovoArray = [...anniSelezionati, value];
      }

      // Ordina l'array in modo decrescente prima di impostarlo nuovamente
      nuovoArray.sort((a, b) => b - a);

      setAnniSelezionati(nuovoArray);
    }

    //Click importo
    const [currentYear, setCurrentYear] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(null);
    const [showFatture, setShowFatture] = useState(false);
    const [fattureVisualizzazione, setFattureVisualizzazione] = useState(null);

    async function handleClick(index, anno){
      const mesi = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"];
      setCurrentYear(anno);
      setCurrentMonth(mesi[index]);
      try {
        const data = {
          meseDesiderato: index,
          annoDesiderato: anno, 
          divisioni: checkedDivisioni
        }

        const response = await axios.post('https://www.app.nauticsm.it/visualizzazione-fatture', data);
        
        setFattureVisualizzazione(response.data);

      } catch (error) {
          console.error('Errore durante la richiesta:', error);
      }

      setShowFatture(true);
    }

    function handleClose(){
      setShowFatture(false);
    }

    //anni selezionati
    
    useEffect(() => {
      if(!start){
        
        setChiamataAnni(true);

      }
    }, [anniSelezionati])

    useEffect(() => {
      if(chiamataAnni){
        fetchData();
      }
    }, [chiamataAnni])


    //graph
    const [seeGraph, setSeeGraph] = useState(false);
    function handleGraph(){
      setSeeGraph(!seeGraph);
    }

    //see year
  const [seeYear, setSeeYear] = useState(false);
  function handleSeeYear(){
    setSeeYear(!seeYear);
  }

  //see filter
  const [seeFilter, setSeeFilter] = useState(false);
  function handleFilter(){
    setSeeFilter(!seeFilter);
    setSeeDivisioni(false);
  }

  return (
    <div>
        <TopMenu name="Vendite" username={username}/>
        
        {businessUnit && (
          <InnerMenu seeFilter={seeFilter}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div className='icon-container tooltip' onClick={handleFilter}>
                <span class="tooltiptext">Filtri</span>
                {seeFilter ? (
                  <FilterOffIcon onClick={handleFilter}/>
                ) : (
                  <FilterIcon onClick={handleFilter}/>
                )}
              </div>
              <div className='icon-container tooltip' style={{marginLeft: '10px'}} onClick={handleGraph}>
                <span class="tooltiptext">Grafico</span>
                <GraphIcon onClick={handleGraph}/>
              </div>
            </div>
            <div className='filter-container'>
              <Divisioni seeDivisioni={seeDivisioni}>
                <button className="divisioni-title" onClick={handleLegend}>Divisioni Aziendali <DownArrow /></button>
                <div className='divisioni-aziendali'>
                  <label><input style={{marginTop: "15px"}} onChange={handleChangeDivisioni} checked={checkedDivisioni.includes("Tutto")}  value="Tutto" type="checkbox"/> Tutto</label><br/>
                  {businessUnit.map((item, index) => (
                    <><label ><input checked={checkedDivisioni.includes(item)} onChange={handleChangeDivisioni} style={{ marginBottom: (index === businessUnit.length - 1) ? '15px' : '0' }} value={item} type="checkbox" /> {item}</label><br/></>
                  ))}
                  <div className='spuntate'>
                    {checkedDivisioni.map((item) => (
                      <p className='check'>{item} <Delete onClick={() => handleRemoveDivisioni(item)}/></p>
                    ))}
                  </div>
                </div>
                
              </Divisioni>
              <YearSelection>
                <div className='select-anni'>
                  <div className='year-selection' onClick={handleSeeYear}>
                    <p>Seleziona anni <DownArrow /></p>
                  </div>

                  <DropDown seeYear={seeYear}>
                    <div className='dropdown-content'>
                      {anni.map((anno) => (
                        <label><input type="checkbox" checked={anniSelezionati.includes(anno)} onChange={handleYearSelection} value={anno}/> {anno}</label>
                      ))}
                    </div>
                  </DropDown>
                </div>
              </YearSelection>
            </div>
            
          </InnerMenu>
        )}

        <Center>
            {dati ? (
                <div className='scroll' style={{marginTop: '0px'}}>
                    <table className='scroll-table'>
                        <thead>
                            <tr className='intestazione'>
                                <th className='block first-column'>Mesi</th>
                                {anniSelezionati.map((anno) => (
                                    <th className='columnNew'>{anno}</th>
                                ))}
                                <th className='columnNew'>FT. Medio</th>
                                <th className='columnNew'>Stagio.</th>
                                <th className='columnNew'>Progresso</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dati.map((item, index) => (
                                <>
                                    <tr>
                                        <td className={item.id === 13 ? 'block first-column last' : 'block first-column'}>{item.mesi}</td>
                                        {anniSelezionati.map((anno) => (
                                            <td style={{textDecoration: item.id !== 13 && item[`es_${anno}`] !== 0 && 'underline', cursor: item.id !== 13 && item[`es_${anno}`] !== 0 && 'pointer'}} className={item.id === 13 ? 'column last' : 'column'} onClick={() => handleClick(index, anno)}>{Number(item[`es_${anno}`]).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                            })}</td>
                                        ))}
                                        <td className={item.id === 13 ? 'column last' : 'column'}>{Number(item.ft_medio).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</td>
                                        <td className={item.id === 13 ? 'column last' : 'column'}>{Number(item.stagio).toFixed(2)}%</td>
                                        <td className={item.id === 13 ? 'column last' : 'column'}>{Number(item.progresso).toFixed(2)}%</td>
                                    </tr>
                                    {item.id === 12 && (
                                        <tr>
                                            <td className='spazio-ridotto'></td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (<Caricamento />)}
        </Center>
        {showFatture && (<VenditePopup currentMonth={currentMonth} currentYear={currentYear} fatture={fattureVisualizzazione} close={handleClose}/>)}

        {seeGraph && (<GraphPopup close={handleGraph} anni={anniSelezionati}/>)}


        <Fatturato dati={datiFatturato} datiGrafico={datiGrafico} datiAggiuntivi={datiAggiuntivi}/>

        
    </div>
  )
}

export default Vendite;

const DropDown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 5px;
  overflow: hidden;
  padding: 0px 10px;
  border: none;
  
  max-height: ${({ seeYear }) => (seeYear ? '500px' : '0px')};
  transition: max-height .5s linear;

  .dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px;

    label {
      padding: 5px 0px;
      font-size: 20px;
    }
  }
`;


const DownArrow = styled(IoIosArrowDown)`
  font-size: 18px;
  margin-left: 5px;
`



const Center = styled.div`
    height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const YearSelection = styled.div`
    padding-right: 2%;
    display: flex;
    align-items: center;
    width: 400px;
    display: flex;
    justify-content: flex-end;


    label, input{
      cursor: pointer;
      font-size: 18px;
    }

    button{
      background-color: black;
      color: white;
      font-size: 18px;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 10px;
    }
`

const InnerMenu = styled.div`
    margin-top: 30px;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;

    .filter-container{
      max-height: ${props => (props.seeFilter ? '400px' : '0px')};
      padding:  ${props => (props.seeFilter ? '20px 0px' : '0px')};
      height: fit-content;
      border: ${props => (props.seeFilter ? '1px solid rgba(0,0,0,.5)' : 'none')};
      overflow: ${props => (props.seeFilter ? 'visible' : 'hidden')};;
      box-shadow: 0px 0px 10px rgba(0,0,0,.2);
      border-radius: 0px 10px 10px 10px;

      transition: max-height 0.3s ease, padding 0.3s ease;
      padding-left: 2%;
      padding-right: 2%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      
    }

    .icon-container{
      border: 1px solid rgba(0,0,0,.5);
      border-bottom: ${props => (props.seeFilter ? 'none' : '1px solid rgba(0,0,0,.5)')};
      width: fit-content;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      border-radius: ${props => (props.seeFilter ? '5px 5px 0px 0px' : '5px')};
      cursor: pointer;
      color: rgba(0,0,0,.5);
      transition: all .3s linear;
    }

    .icon-container:hover{
      border: 1px solid black;
      color: black;
    }
`

const Divisioni = styled.div`

  .spuntate .check{
    background-color: lightgrey;
    margin-right: 8px;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .divisioni-title{
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0,0,0,.5);
    padding: 5px 20px;
    color: rgba(0,0,0,.5);
    background-color: white;
    transition: all .3s linear;

  }

  .divisioni-title:hover{
    color: black;
    border: 1px solid black;
  }

  .spuntate{
    display: flex;
    align-items: center;
    margin-top: ${({seeDivisioni}) => (seeDivisioni ? '10px' : '0px')};
    margin-bottom: 10px;
    max-width: 300px;
    flex-wrap: wrap;
    height: fit-content;

    p{
      margin: 3px 0px;
    }
  }
  
  .divisioni-aziendali{
    position: absolute;
    background-color: white;
    z-index: 9998;
    max-height: ${({seeDivisioni}) => (seeDivisioni ? '400px' : '0')};
    height: fit-content;
    box-shadow: 0px 0px 3px rgba(0,0,0,.4);
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    border-radius: 10px;
    width: fit-content;
    margin-top: 3px;
    font-size: 18px;
    padding: 0px 50px 0px 10px;
  }

  label, input{
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
  } 
`

const Delete = styled(IoMdClose)`
  font-size: 16px;
  cursor: pointer;
  margin-top: 1px;
  margin-left: 2px;
`

const FilterIcon = styled(FaFilter)`
  font-size: 20px;
  cursor: pointer;  
`
const FilterOffIcon = styled(MdFilterAltOff)`
  font-size: 24px;
  cursor: pointer;  
`

const GraphIcon = styled(VscGraph)`
  font-size: 20px;
  cursor: pointer;
`