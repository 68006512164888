import React, {useState, useEffect} from 'react'
import styled, {keyframes} from "styled-components";

//icon
import { FaSpinner } from "react-icons/fa";

function Caricamento() {

    const [dots, setDots] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length < 3) {
          return prevDots + '.'; // Aggiungi un punto
        } else {
          return ''; // Reset dei punti
        }
      });
    }, 500); // Intervallo di 500 ms

    // Pulisce l'intervallo quando il componente viene smontato
    return () => clearInterval(interval);
  }, []);
  return (
    <Container>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <IconaSpinner />
            <p style={{fontSize: '20px', color: 'white'}}>Solo un momento{dots}</p>
        </div>
    </Container>
  )
}

export default Caricamento;

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;


const Container = styled.div`
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: rgba(0,0,0,.7);
    width: 100%;
    height: 100%;
    z-index: 9998;
    display: flex;
    align-items: center;
    justify-content: center;
`


const IconaSpinner = styled(FaSpinner)`
  animation: ${spin} 1s linear infinite;
  color: white;
  font-size: 120px;

`