import React, {useState, useEffect} from 'react';

import styled from "styled-components";
//icon
import { IoMdClose } from "react-icons/io";
import { FaCalendarAlt } from "react-icons/fa";


import axios from "axios";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


function CashFlowPopup({close, fattura, tipo, ricarica, aggiornamento}) {

  const [startOperation, setStartOperation] = useState(true);


    function handleClose(){
        close();
    }

    const [totale, setTotale] = useState(null);

    useEffect(() => {
      console.log(fattura);
      if(fattura !== undefined && fattura !== null){
        setTotale(fattura.importo);
      }
    }, [fattura])

    const [eliminate, setEliminate] = useState(null);
    const [arrayIdFattura, setArrayIdFattura] = useState([]);
    const [arrayImportiFattura, setArrayImportiFattura] = useState([]);


    const fetchData = async () => {
      try {
        const response = await axios.get('https://www.app.nauticsm.it/eliminate-cashflow');

        setEliminate(response.data);
        
        setArrayIdFattura(response.data.map(obj => obj.id_fattura));
        setArrayImportiFattura(response.data.map(obj => obj.importo_fattura));

        
        
      } catch (error) {
        console.error('Errore durante la richiesta dei dati:', error);
      }
    };

    useEffect(() => {
      fetchData();
      console.log(fattura);
    }, [])

    function ricaricaDati(){
      console.log("***** chiamata file cashflow popup *****");
      ricarica();
      fetchData();
      console.log(fattura);
    }

    // useEffect(() => {
    //   console.log(arrayImportiFattura);
    //   for(let i =0;i<arrayIdFattura.length;i++){
    //     if(fattura.invoiceIds.includes(arrayIdFattura[i])){
    //       setTotale(prevTotale => prevTotale - arrayImportiFattura[i]);
    //     }
    //   }
      
    // }, [arrayImportiFattura])


    const [importi, setImporti] = useState([]);
    const [tipoOperazione, setTipoOperazione] = useState(null);
    const [lastImporto, setLastImporto] = useState(null);
    const [idEliminati, setIdEliminati] = useState([]);
    const [debounceTimer, setDebounceTimer] = useState(null);

    function handleChecked(id){
      
      if(arrayIdFattura.includes(id)){      
        
        setArrayIdFattura(prevArrayIdFattura => prevArrayIdFattura.filter(item => item !== id));
        setIdEliminati(prevIdEliminati => [...prevIdEliminati, id]);

        setTipoOperazione(false);
        for(let i=0;i<fattura.invoiceIds.length;i++){
          
          if(fattura.invoiceIds[i] === id){
            if(fattura.credito[i] === true){
              setLastImporto(-fattura.importoSing[i]);
              setImporti(prevImporti => prevImporti.filter(item => item !== -fattura.importoSing[i]));
            }else {
              setLastImporto(fattura.importoSing[i]);
              setImporti(prevImporti => prevImporti.filter(item => item !== fattura.importoSing[i]));
            }
            
          }
        }

      } else{

        setTipoOperazione(true);
        for(let i=0;i<fattura.invoiceIds.length;i++){
          
          if(fattura.invoiceIds[i] === id){
            if(fattura.credito[i] === true){
              setImporti(prevImporti => [...prevImporti, -fattura.importoSing[i]]);
            }else {
              setImporti(prevImporti => [...prevImporti, fattura.importoSing[i]]);
            }
            
          }
        }
        setIdEliminati(prevIdEliminati => prevIdEliminati.filter(item => item !== id));
        setArrayIdFattura(prevArrayIdFattura => [...prevArrayIdFattura, id]);
      }


      
    }
    
    useEffect(() => {
      
      if (debounceTimer) {
        clearTimeout(debounceTimer); // cancella il timer precedente se esiste
      }

      const newTimer = setTimeout(() => {
        if(startOperation){
          console.log("primo");
          setStartOperation(false);
        } else {
          console.log("secondo");
          if(tipoOperazione){
            setTotale(prevTotale => prevTotale - importi[importi.length - 1]);
          } else{ 
            setTotale(prevTotale => prevTotale + lastImporto);
          }
    
          let dati = {
            id: arrayIdFattura,
            importi: importi,
            eliminati: idEliminati
          }
    
          fetch('https://www.app.nauticsm.it/nuove-fatture', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dati),
        })
          .then(response => response.json())
          .then(data => {
            console.log('Risposta dal server:', data);
            ricaricaDati();
          })
          .catch(error => {
            console.error('Errore durante la richiesta:', error);
          });
        }
      }, 500);

      setDebounceTimer(newTimer);

      return() => {
        clearTimeout(newTimer);
      }
      
    
  }, [importi])

  //apertura calendario
  const [idData, setIdData] = useState(null);
  const [idSel, setIdSel] = useState(null);

  const [sendData, setSendData] = useState({
    data: '',
    id: 0,
  })

  const openCalendar = (data, idSel) => {
    const [day, month, year] = data.split('/').map(Number);

    // JavaScript conta i mesi da 0 a 11, quindi sottrai 1 al mese
    const nuovaData = new Date(year, month - 1, day);
    setIdData(nuovaData);
    setIdSel(idSel)
  };

  function closeCalendarBtn(){
    setIdData(null);
  }

  const closeCalendar = (data) => {
    setIdData(null);
    
    let dataNew = new Date(data);
    let giorno = dataNew.getDate();
    let mese = (dataNew.getMonth() + 1).toString().padStart(2, '0');
    let anno = dataNew.getFullYear();

    let dataFormattata = giorno+"/"+mese+"/"+anno;
    
    console.log("close - id " + idSel);
    console.log("close - data " + data);

    setSendData({
      ...sendData,
      data: dataFormattata, 
      id: idSel, 
    });
    
  };

  useEffect(() => {
    
    if (idSel !== null && sendData.data !== '') {
      
      const fetchData = () => {

        console.log("invio nuova data!!!");

        fetch('https://www.app.nauticsm.it/data-cashflow', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sendData),
        })
        .then(response => {
          
            if (!response.ok) {
                throw new Error('Errore nella richiesta fetch');
            }
            return response.json();
        })
        .then(data => {
          
          ricaricaDati();
        })
        .catch(error => {
            console.error('Errore durante l\'invio dei dati al server:', error);
        });
      };
    
  
      fetchData();
    }
  }, [idSel, sendData]);
    
    

  return (
    <Opacity>
        <Popup>
            <CloseIcon onClick={aggiornamento !== 'In aggiornamento...' && handleClose} style={{ cursor: aggiornamento !== 'In aggiornamento...' ? 'pointer' : 'not-allowed' }}/>
            {fattura && fattura.invoiceIds && (
              <>
                {fattura.mese === 'Mese non valido' ? (
                  <h1>Fatture {tipo !== 'entrate' && 'uscite'} precedenti</h1>
                ) : (
                  <h1>Fatture {tipo !== 'entrate' && 'uscite'} {fattura.mese}</h1>
                )}
                <p>Stato: {aggiornamento}</p>
                <div className='fatture'>
                    <table className='cashflow-table'>
                      <thead>
                        <tr>
                          <th>Incassata</th>
                          <th>N.</th>
                          <th>Data Creazione</th>
                          <th>Data Scadenza</th>
                          <th>Data Previsto {tipo === 'entrate' ? 'incasso' : 'pagamento'}</th>

                          {tipo === 'entrate' ? (
                            <React.Fragment>
                              <th>Venditore</th>
                              <th>Cliente</th>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <th>Cliente</th>
                              <th>Venditore</th>
                            </React.Fragment>
                          )}
                          <th>Importo (IVA incl.)</th>
                          <th>Metodo Pagamento</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fattura.invoiceIds.map((item, index) => (
                          <>
                            {!arrayIdFattura.includes(item) && (
                              <tr >
                                <td ><input className='input-cash' checked={arrayIdFattura.includes(item) && true} type="checkbox" onChange={() => handleChecked(item)}/></td>
    
                                <td >{fattura.docNum[index]}</td>
                                <td>{fattura.dataCreazioneFattura[index]}</td>
                                <td >{fattura.dataScadenza[index]}</td>
                                <td >{fattura.dataPrevistoIncasso[index]} <CalendarIcon onClick={() => openCalendar(fattura.dataPrevistoIncasso[index], item)}/></td>
    
                                {idData && (
                                  <div className="calendar-overlay">
                                    <div className="calendar-container">
                                      <CloseIcon onClick={closeCalendarBtn}/>
                                      <br/>
                                      <Calendar value={idData} onChange={(newDate) => closeCalendar(newDate)} />
                                    </div>
                                  </div>
                                )}
                                
                                <td >Vinco srls</td>
                                <td >{fattura.cliente[index]}</td>
    
                                {fattura.credito[index] === true ? (<td >-{Number(fattura.importoSing[index]).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                })}</td>) : (<td >{Number(fattura.importoSing[index]).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                })}</td>)}
    
                                <td >{fattura.tipoPagamento[index]}</td>
                              </tr>
                            )}
                          </>
                        ))}
                        {fattura.invoiceIds.map((item, index) => (
                          <>
                            {arrayIdFattura.includes(item) && (
                              <tr >
                                <td className={arrayIdFattura.includes(item) && 'checked'}><input className='input-cash' checked={arrayIdFattura.includes(item) && true} type="checkbox" onChange={() => handleChecked(item)}/></td>
    
                                <td className={arrayIdFattura.includes(item) && 'checked'}>{fattura.docNum[index]}</td>
                                <td className={arrayIdFattura.includes(item) && 'checked'}>{fattura.dataCreazioneFattura[index]}</td>
                                <td className={arrayIdFattura.includes(item) && 'checked'}>{fattura.dataScadenza[index]}</td>
                                <td className={arrayIdFattura.includes(item) && 'checked'}>{fattura.dataPrevistoIncasso[index]} <CalendarIcon onClick={() => openCalendar(fattura.dataPrevistoIncasso[index], item)}/></td>
    
                                {idData && (
                                  <div className="calendar-overlay">
                                    <div className="calendar-container">
                                      <CloseIcon onClick={closeCalendarBtn}/>
                                      <br/>
                                      <Calendar value={idData} onChange={(newDate) => closeCalendar(newDate)} />
                                    </div>
                                  </div>
                                )}
                                
                                <td className={arrayIdFattura.includes(item) && 'checked'}>Vinco srls</td>
                                <td className={arrayIdFattura.includes(item) && 'checked'}>{fattura.cliente[index]}</td>
    
                                {fattura.credito[index] === true ? (<td className={arrayIdFattura.includes(item) && 'checked'}>-{Number(fattura.importoSing[index]).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                })}</td>) : (<td className={arrayIdFattura.includes(item) && 'checked'}>{Number(fattura.importoSing[index]).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                })}</td>)}
    
                                <td className={arrayIdFattura.includes(item) && 'checked'}>{fattura.tipoPagamento[index]}</td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>
                    <div className='totale-mese'>
                      <p>Totale {fattura.mese}: {Number(totale).toLocaleString('it-IT', {
                              style: 'currency',
                              currency: 'EUR'
                        })}</p>
                    </div>
                </div>
              </>
            )}
        </Popup>
    </Opacity>
  )
}

export default CashFlowPopup;

const Popup = styled.div`
 position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  min-width: 800px;
  padding: 20px;
  background-color: white;
  z-index: 9999;
  border-radius: 10px;

  .fatture{
    max-height: 60vh;
    overflow-y: auto; 
    width: 100%;
    margin-top: 15px;
  }

`

const Opacity = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`

const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`

const CalendarIcon = styled(FaCalendarAlt)`cursor: pointer; font-size: 18px; margin-left: 5px;`

