import React, { useEffect, useState } from 'react'
import axios from "axios";
import styled from "styled-components"

//component
import TopMenu from '../InnerComponents/TopMenu'
import Caricamento from "../Caricamento.js"

//icon
import { PiNumberOneBold } from "react-icons/pi";
import { PiNumberThreeBold } from "react-icons/pi";
import { FaLongArrowAltRight } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";

function Iva() {

  const mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
  const currentMonth = new Date().getMonth();

  const [datiTabella, setDatiTabella] = useState(null);
  const [visualizzazione, setVisualizzazione] = useState(null);
  const [visualizzazioneTotale, setVisualizzazioneTotale] = useState(null);
  const [start, setStart] = useState(false);

  async function datiIva() {
    try {
      const response = await axios.get('https://www.app.nauticsm.it/dati-iva');
      
      setDatiTabella(response.data);
      setVisualizzazione(response.data.tipo);    
      setVisualizzazioneTotale(response.data.visualizzazione);
      setStart(true);
     

    } catch (error) {
      console.error('Errore durante la richiesta POST:', error);
    }

  }

  useEffect(() => {
    datiIva();
  }, [])


  //cambia visualizzazione
  async function cambiaVisualizzazione() {
    try {
      const data = {
        tipo: visualizzazione
      }

      const response = await axios.post('https://www.app.nauticsm.it/cambia-visualizzazione', data);
      
      setVisualizzazioneTotale(response.data);

    } catch (error) {
      console.error('Errore durante la richiesta POST:', error);
    }

  }

  function handleChangeView(){
    
    if(visualizzazione === 'mensile'){
      setVisualizzazione('trimestrale')
    }else {
      setVisualizzazione('mensile');
    }
    
  }

  useEffect(() => {
    if(start){
      cambiaVisualizzazione();
    }
  }, [visualizzazione])

  const [visualizzazioneEstesa, setVisualizzazioneEstesa] = useState(false);

  function handleEstendi(){
    setVisualizzazioneEstesa(!visualizzazioneEstesa);
  }

  return (
    <div>
        <TopMenu name="IVA" />
        <UpperMenu visualizzazione={visualizzazione}>
          {datiTabella && (
            <div className='icon-container tooltip' onClick={handleChangeView}>
              
              {visualizzazione === 'mensile' ? (
                <>
                <span class="tooltiptext">Visualizzazione da mensile a trimestrale</span>
                <OneIcon/> <ArrowIcon/> <ThreeIcon />
                </>
              ) : (
                <><span class="tooltiptext">Visualizzazione da trimestrale a mensile</span><ThreeIcon/> <ArrowIcon/> <OneIcon /></>
              )}
              
            </div>
          )}
          
        </UpperMenu>
        
        <Container>
          <div style={{width: '90%'}}>
          <h2 className='titolo-visualizzazione'>Visualizzazione {visualizzazione}</h2>
          {datiTabella ? (
            <div className='scroll' style={{marginRight: '0px', marginLeft: '0px'}}>
                <table className='scroll-table'>
                  <thead>
                    <tr className='intestazione'>
                      <th className='block first-column'><div style={{display: 'flex', justifyContent: 'space-between', height: '100%', alignItems: 'center'}}>Categoria <span style={{display: 'flex', alignItems: 'center'}}>{!visualizzazioneEstesa ? (<PlusIcon onClick={handleEstendi}/>) : (<MinusIcon onClick={handleEstendi}/>)}</span></div></th>
                      
                      {Array.from({ length: 2 }).map((_, outerIndex) => 
                        <>
                          
                            {mesi.map((item, innerIndex) => (
                              <>
                                {!visualizzazioneEstesa ? (
                                  <>
                                    {outerIndex === 0 ? (
                                      innerIndex >= currentMonth && (
                                        <th className='columnNew' key={`${item}-${innerIndex}`}>
                                          {item} <span style={{ fontSize: '12px' }}>({new Date().getFullYear()})</span>
                                        </th>
                                      )
                                    ) : (
                                      <th className='columnNew' style={{padding: '3px 5px'}} key={`${item}-${innerIndex}`}>
                                        {item} <span style={{ fontSize: '12px' }}>({new Date().getFullYear() + 1})</span>
                                      </th>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {outerIndex === 0 ? (
                                      
                                        <th className='columnNew' key={`${item}-${innerIndex}`}>
                                          {item} <span style={{ fontSize: '12px' }}>({new Date().getFullYear()})</span>
                                        </th>
                                      
                                    ) : (
                                      <th className='columnNew' style={{padding: '3px 5px'}} key={`${item}-${innerIndex}`}>
                                        {item} <span style={{ fontSize: '12px' }}>({new Date().getFullYear() + 1})</span>
                                      </th>
                                    )}
                                  </>
                                )}
                              </>
                            ))}
                          
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                      <td className='block first-column'>IVA Fatture Vendita</td>
                      {datiTabella.vendita.map((item, index) => (
                        <>
                          {!visualizzazioneEstesa ? (
                            <>
                              {index >= currentMonth && (
                                <td className='column'>{Number(item).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                })}</td>
                              )}
                            </>
                          ) : (
                            
                              <td className='column'>{Number(item).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                              })}</td>
                            
                          )}
                        </>
                      ))}

                    </tr>

                    <tr>
                      <td className='block first-column'>IVA Fatture Acquisto</td>
                      {datiTabella.acquisto.map((item, index) => (
                        <>
                        {!visualizzazioneEstesa ? (
                            <>
                              {index >= currentMonth && (
                                <td className='column'>{Number(item).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                })}</td>
                              )}
                            </>
                          ) : (
                            
                              <td className='column'>{Number(item).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                              })}</td>
                            
                          )}
                      </>
                      ))}

                    </tr>

                    <tr>
                      <td className='block first-column last'>Totale</td>
                      {datiTabella.totale.map((item, index) => (
                        <>
                        {!visualizzazioneEstesa ? (
                            <>
                              {index >= currentMonth && (
                                <td className='column last'>{Number(item).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                })}</td>
                              )}
                            </>
                          ) : (
                            
                              <td className='column last'>{Number(item).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                              })}</td>
                            
                          )}
                      </>
                      ))}

                    </tr>

                    <tr>
                      <td className='block first-column last'>Visualizzazione CashFlow</td>
                      {visualizzazioneTotale.map((item, index) => (
                        <>
                        {!visualizzazioneEstesa ? (
                            <>
                              {index >= currentMonth && (
                                <td className='column last'>{Number(item).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                })}</td>
                              )}
                            </>
                          ) : (
                            
                              <td className='column last'>{Number(item).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                              })}</td>
                            
                          )} 
                      </>
                      ))}

                    </tr>
                    
                  </tbody>
                </table>
              </div>
            ) : (<Caricamento />)}
          </div>
        </Container>
    </div>
  )
}

export default Iva;

const UpperMenu = styled.div`
  height: 5vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2%;
  margin-top: 30px;

  

  .icon-container{
    border: 1px solid rgba(0,0,0,.5);
    border-bottom: ${props => (props.seeFilter ? 'none' : '1px solid rgba(0,0,0,.5)')};
    width: fit-content;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: ${props => (props.seeFilter ? '5px 5px 0px 0px' : '5px')};
    cursor: pointer;
    color: rgba(0,0,0,.5);
    transition: all .3s linear;
  }

  .icon-container:hover{
    border: 1px solid black;
    color: black;
  }

  

`

const Container = styled.div`
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .titolo-visualizzazione{
    width: 100%;
    
  }

`

const OneIcon = styled(PiNumberOneBold)`
  font-size: 20px;
  cursor: pointer;
`

const ThreeIcon = styled(PiNumberThreeBold)`
  font-size: 20px;
  cursor: pointer;
`
const ArrowIcon = styled(FaLongArrowAltRight)`
  font-size: 20px;
  cursor: pointer;
`
const PlusIcon = styled(CiCirclePlus)`
  font-size: 28px;
  cursor: pointer;
  cursor: pointer;
  color: white;
`
const MinusIcon = styled(CiCircleMinus)`
  font-size: 28px;
  cursor: pointer;
  cursor: pointer;
  color: white;
`

